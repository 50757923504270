<template>
    <div>
        <ModalCommon :id="id" :config="configModalTypeTitle" @save="handleSaveTypeTitle()" @close="handleCloseTypeTitle()" :isView="true">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ this.$t('pageCampaign.tabPrize.type') }}</label>
                    <div class="col-sm-9">
                        {{ formDataType.master_id?.value ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ formDataType?.master_id?.value ?? '' }}</label>
                    <div class="col-sm-9">
                        {{ handleJoinValue(formDataType) }}
                    </div>
                </div>

                <div v-if="formDataType?.master_id?.value == 'デジタル'">
                    <div v-for="(ite, idx) in formDataType.children_data" :key="idx">
                        <div v-if="ite?.children_data?.length">
                            <div class="form-group row">
                                <label class="col-sm-3 mw--form" for="prizeLessStatus"> {{ ite?.master_id?.value ?? '' }}</label>
                                <div class="col-sm-9">
                                    {{ handleJoinValue(ite) }}
                                </div>
                            </div>

                            <div v-for="(children_data, ik) in ite.children_data" :key="ik" class="form-group row">
                                <label class="col-sm-3 mw--form" for="prizeLessStatus"> {{ children_data?.master_id?.value ?? '' }}</label>
                                <div class="col-sm-9">
                                    <p v-html="textLineBreaks(children_data.note.electronic_money_point_info) ?? ''"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="formDataType?.master_id?.id" class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ $t('pageCampaign.tabPrize.typeTitle.over500') }}</label>
                    <div class="col-sm-9">
                        <InputCheckBox
                            class="mr-3 mb-2"
                            :model.sync="isHighCouponValue"
                            :id="`check-${formDataType?.master_id?.value ?? ''}`"
                            :value="false"
                            :label="''"
                            disabled
                        />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { InputCheckBox } from '@/components/Input';
import { CONFIG_MODALTYPETITLE } from '@/components/Campaign/TabPrize/constans';
import { renderLineBreaks } from '@/utils/format';

import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'ModalTypeTitle',
    components: {
        ModalCommon,
        InputCheckBox
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        isHighCouponValue: {
            type: Boolean,
            default: false
        },
        dataTypes: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            formDataType: this.dataTypes,
            configModalTypeTitle: CONFIG_MODALTYPETITLE
        };
    },

    methods: {
        handleSaveTypeTitle() {
            this.$emit('update:isShow', false);
        },

        handleCloseTypeTitle() {
            this.$emit('update:isShow', false);
        },

        textLineBreaks(text) {
            return renderLineBreaks(text);
        },

        handleJoinValue(param) {
            if (!param && param.length <= 0) return '';
            return param.children_data.map((item) => item?.master_id?.value).join('、');
        }
    },
    watch: {
        title: {
            handler: function (valTitle) {
                this.configModalTypeTitle.title = valTitle;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalTypeTitle.model = valShow;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
