<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-1">発注元</label>
            <div class="col-sm-3">
                {{ dataForm?.client_order_source?.name ?? '' }}
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <div class="table-responsive" style="height: 440px; overflow-y: auto">
                    <table class="table table-bordered table-box custom__table--sticky">
                        <thead>
                            <tr>
                                <th>エンドクライアント名</th>
                                <th>担当</th>
                                <th>部署</th>
                                <th>メールアドレス</th>
                                <th>顧客種別</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, k) in dataForm.clients" :key="k + 'fc'">
                                <td class="text-left">
                                    <div class="row-data-small">{{ item?.client?.name ?? '' }}</div>
                                </td>
                                <td class="text-left">
                                    <div class="row-data-small">{{ item?.client_pic?.name ?? '' }}</div>
                                </td>
                                <td class="text-left">
                                    <div class="row-data-small">{{ item?.client_pic?.department ?? '' }}</div>
                                </td>
                                <td class="text-left">
                                    <div class="row-data-small">{{ item?.client_pic?.email ?? '' }}</div>
                                </td>
                                <td class="text-left">
                                    <div class="row-data-small">
                                        {{ renderType(item?.client?.type_id) ?? '' }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { OPTIONS_TYPE } from '@/components/Campaign/TabClients/constants';

export default {
    name: 'TableClients',
    props: {
        dataForm: Object,
        default: () => {}
    },
    methods: {
        renderType(type) {
            if (!type) return '';
            let index = OPTIONS_TYPE.findIndex((x) => x.id == type);
            if (index >= 0) return OPTIONS_TYPE[index].name;
        }
    }
};
</script>

<style lang="scss" scoped></style>
