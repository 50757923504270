<template>
    <div class="box__components--prize">
        <div class="table-responsive table-prize" style="height: 500px; overflow-y: auto; outline: none">
            <table class="table table-bordered custom__table--sticky table-box">
                <thead>
                    <tr :class="{ noteFix: arrayObjectPizes.length > 0 }">
                        <th
                            v-for="(item, k) in configTable.headers"
                            :key="k"
                            :style="{ 'background-color': item.bgr }"
                            style="white-space: nowrap; padding: 0.5rem"
                        >
                            <div class="d-flex align-items-center" style="min-width: max-content; gap: 5px">
                                {{ item.label }}
                            </div>
                        </th>
                    </tr>
                    <tr :key="`prize-detail-each-title`" :class="{ 'prize__detail--title': arrayObjectPizes.length > 0 }">
                        <th
                            v-for="(child, childIdx) in configTable.headersChild"
                            :key="`child-${childIdx}`"
                            :style="{ 'background-color': child.bgr }"
                            style="white-space: nowrap; padding: 0.5rem"
                        >
                            <div class="d-flex align-items-center" style="gap: 5px">
                                {{ child.label }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, k) in arrayObjectPizes">
                        <tr :key="k" style="border-top: 2px solid #b3b7bb">
                            <td style="vertical-align: middle; text-align: center">
                                <div>
                                    {{ k + 1 }}
                                </div>
                            </td>

                            <!-- 賞品タイトル/ title phần thưởng -->
                            <td>
                                <div class="row-data-medium w-100" v-b-tooltip.hover :title="item.title">
                                    {{ item.title }}
                                </div>
                            </td>

                            <!-- 種類/ phân loại  -->
                            <td>
                                <div>
                                    <ModalTypeTitle
                                        v-if="isActiveComponent(null, k, activedType)"
                                        :id="`popup-type-${k}`"
                                        :title="item.title"
                                        :isShow.sync="configModalTypeTitle.model"
                                        :dataTypes.sync="item.types"
                                        :isHighCouponValue.sync="item.is_high_coupon_value"
                                    />
                                    <button type="button" class="btn btn-sm btn-primary mb-0" @click="handleOpenType(null, k)">
                                        {{ $t('pageCampaign.tabPrize.type') }}
                                    </button>
                                </div>
                            </td>

                            <!-- 当選人数/ số người trúng  -->
                            <td>
                                <div class="row-data-small w-100">
                                    {{ item.number_of_win_prize }}
                                </div>
                            </td>

                            <!-- 抽選人数/ số người bốc thăm  -->
                            <td>
                                <div class="row-data-small w-100">
                                    {{ item.number_of_people_drawing_lots }}
                                </div>
                            </td>

                            <!-- 発送・進呈予定日/ ngày dự định theo lịch, giao hàng -->
                            <td>
                                <div class="row-data-small w-100">
                                    {{ handleFormatDate(item.delivery_date_schedule, 'dateJP') ?? '' }}
                                </div>
                            </td>

                            <!-- 発送日・進呈日/ ngày dự định, giao hàng  -->
                            <td>
                                <div class="row-data-small w-100">
                                    {{ handleFormatDate(item.delivery_date, 'dateJP') ?? '' }}
                                </div>
                            </td>

                            <!-- 発送件数/ số lượng giao hàng  -->
                            <td>
                                <div class="row-data-small w-100">
                                    {{ item.delivery_quantity }}
                                </div>
                            </td>

                            <!-- 戻り詳細/ chi tiết trả về -->
                            <td>
                                <div>
                                    <button type="button" class="btn btn-sm btn-primary mb-0" @click="handleOpenDetailReturn(null, k)">
                                        {{ $t('pageCampaign.tabPrize.returnDetails') }}
                                    </button>
                                    <ModalDetailsReturns
                                        v-if="isActiveComponent(null, k, activeModalDetailsReturns)"
                                        :id="`modal-detail-return-${k}`"
                                        :isShow.sync="configDetailReturn.model"
                                        :dataDetailReturns.sync="item.return_prize_items"
                                        :returnPrizeItemsCount.sync="item.return_prize_items_count"
                                    />
                                </div>
                            </td>

                            <!-- 戻り件数/ số phần thưởng trả về -->
                            <td>
                                <div class="row-data-small w-100" v-b-tooltip.hover :title="item.return_prize_items_count">
                                    {{ item.return_prize_items_count }}
                                </div>
                            </td>

                            <td>
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-sm"
                                        style="white-space: nowrap; outline: none; box-shadow: none"
                                        @click="handleActivePrizesForTitle(item)"
                                    >
                                        <i v-if="item.isActivePrize" class="mdi mdi-chevron-double-down" style="color: #5664d2; font-size: 20px"></i>
                                        <i v-else class="mdi mdi-dots-horizontal" style="color: #5664d2; font-size: 20px"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <template v-if="item.isActivePrize">
                            <tr
                                v-for="(itemPN, idx) in item.arrayNameAndQuantity"
                                :key="'arrayObjectPizes' + k + idx"
                                :class="{
                                    noteFix: idx == 0
                                }"
                            >
                                <td>
                                    <div></div>
                                </td>

                                <!-- 賞品名/ tên phần thưởng  -->
                                <td>
                                    <div :key="idx">
                                        <div class="row-data-medium w-100" v-b-tooltip.hover :title="itemPN.name">
                                            {{ itemPN.name }}
                                        </div>
                                    </div>
                                </td>

                                <!-- 数量/ số lượng cho từng phần thưởng  -->
                                <td>
                                    <div class="row-data-small w-100">
                                        {{ itemPN.quantity }}
                                    </div>
                                </td>

                                <!-- 賞品必要数/ số lượng phần thưởng cần thiết  -->
                                <td>
                                    <div class="row-data-small w-100" v-b-tooltip.hover :title="itemPN.quantity_for_event_prize">
                                        {{ itemPN.quantity_for_event_prize }}
                                    </div>
                                </td>

                                <!-- 入庫予定日/ ngày dự định nhập kho  -->
                                <td>
                                    <div class="row-data-small w-100">
                                        {{ handleFormatDate(itemPN.scheduled_arrived_at, 'dateJP') ?? '' }}
                                    </div>
                                </td>

                                <!-- 入庫日/ ngày nhập kho -->
                                <td>
                                    <div class="row-data-small w-100">
                                        {{ handleFormatDate(itemPN.warehousing_date, 'dateJP') ?? '' }}
                                    </div>
                                </td>

                                <!-- 入庫数/ số lượng nhập kho  -->
                                <td>
                                    <div class="row-data-small w-100">
                                        {{ itemPN.warehousing_quantity }}
                                    </div>
                                </td>

                                <!-- 残予定数/ số lượng dự định còn  -->
                                <td>
                                    <div class="row-data-small w-100" v-b-tooltip.hover :title="itemPN.estimated_remaining_quantity">
                                        {{ itemPN.estimated_remaining_quantity }}
                                    </div>
                                </td>

                                <!-- メモ（連絡事項）/ memo (mục liên lạc) -->
                                <td>
                                    <div class="row-data-small w-100" v-b-tooltip.hover :title="itemPN.memo">
                                        {{ itemPN.memo }}
                                    </div>
                                </td>

                                <!-- 詳細情報? thông tin chi tiết  -->
                                <td>
                                    <div>
                                        <PopUpInfomation
                                            v-if="isActiveComponent(idx, k, activedInfo)"
                                            :id="`popup-info-${k}-${idx}`"
                                            :title="itemPN.name"
                                            :isShow.sync="configPopInfomation.isShow"
                                            :dataInfo.sync="itemPN.detail_information"
                                        />
                                        <PopoverPreviewInfo
                                            :id="`popover-infomation-${idx}-${k}`"
                                            :title="itemPN.name"
                                            :dataPreview="itemPN.detail_information"
                                        >
                                            <button
                                                :id="`popover-infomation-${idx}-${k}`"
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                @click="handleShowPopupInfo(idx, k)"
                                            >
                                                {{ $t('pageCampaign.tabPrize.detailedInformation') }}
                                            </button>
                                        </PopoverPreviewInfo>
                                    </div>
                                </td>

                                <!-- 実あまり数/ số lượng còn lại thực tế -->
                                <td>
                                    <div
                                        class="row-data-small w-100"
                                        v-b-tooltip.hover
                                        :title="itemPN.remaining_quantity"
                                        :style="{ color: itemPN.configRemainingQuantity.hasWarning ? '#ff3d60' : '' }"
                                    >
                                        {{ itemPN.remaining_quantity }}
                                    </div>
                                </td>

                                <!-- あまり対応/ đối ứng còn thừa -->
                                <td>
                                    <div>
                                        <PopUpCompatible
                                            v-if="isActiveComponent(idx, k, activeCompatible)"
                                            :id="`popup-compatible-${k}-${idx}`"
                                            :title="itemPN.name"
                                            :isShow.sync="configPopCompatible.isShow"
                                            :dataCompatible.sync="itemPN.remaining_response"
                                        />
                                        <PopoverPreviewCompatible
                                            :id="`popover-preview-compatible-${idx}-${k}`"
                                            :title="itemPN.name"
                                            :dataPreview="itemPN.remaining_response"
                                        >
                                            <button
                                                :id="`popover-preview-compatible-${idx}-${k}`"
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                @click="handleShowPopupCompatible(idx, k)"
                                            >
                                                {{ $t('pageCampaign.tabPrize.notVeryCompatible') }}
                                            </button>
                                        </PopoverPreviewCompatible>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { initObjectPize } from '@/components/Campaign/TabPrize/index';
import {
    CONFIG_TABLE,
    CONFIG_INFOMATION,
    CONFIG_COMPATIBLE,
    CONFIG_MODAL_DETAIL_RETURN,
    CONFIG_MODALTYPETITLE
} from '@/components/Campaign/TabPrize/constans';
import { formatDateCommon } from '@/utils/format';

import PopUpInfomation from './components/Infomation/pop-up-infomation.vue';
import PopoverPreviewInfo from './components/Infomation/popover-preview.vue';
import PopUpCompatible from './components/NotVeryCompatible/pop-up-compatible.vue';
import PopoverPreviewCompatible from './components/NotVeryCompatible/popover-preview.vue';
import ModalDetailsReturns from './components/ModalDetailsReturns/main.vue';
import ModalTypeTitle from './components/ModalTypeTitle/main.vue';

export default {
    props: {
        dataEventPrizes: {
            type: Array,
            default: () => []
        }
    },
    components: {
        PopUpInfomation,
        PopoverPreviewInfo,
        PopUpCompatible,
        PopoverPreviewCompatible,
        ModalDetailsReturns,
        ModalTypeTitle
    },
    data() {
        return {
            arrayObjectPizes: [],
            configTable: CONFIG_TABLE,
            configPopInfomation: CONFIG_INFOMATION,
            configPopCompatible: CONFIG_COMPATIBLE,
            configDetailReturn: CONFIG_MODAL_DETAIL_RETURN,
            configModalTypeTitle: CONFIG_MODALTYPETITLE,
            activedInfo: null,
            activeCompatible: null,
            activeModalDetailsReturns: null,
            activedType: null
        };
    },
    methods: {
        handleShowPopupInfo(idx, k) {
            this.activedInfo = { idx, k };
            this.activeCompatible = null;
            this.configPopInfomation.isShow = true;
        },

        handleShowPopupCompatible(idx, k) {
            this.activeCompatible = { idx, k };
            this.activedInfo = null;
            this.configPopCompatible.isShow = true;
        },

        handleOpenDetailReturn(idx, k) {
            this.activeModalDetailsReturns = { idx, k };
            this.configDetailReturn.model = true;
        },

        isActiveComponent(idx, k, btnActive) {
            return btnActive && btnActive.idx === idx && btnActive.k === k;
        },
        handleActivePrizesForTitle(param) {
            if (param.isActivePrize) {
                param.isActivePrize = false;
            } else {
                param.isActivePrize = true;
            }
        },

        handleOpenType(idx, k) {
            this.activedType = { idx, k };
            this.configModalTypeTitle.model = true;
        },

        handleFormatDate(date, type) {
            return formatDateCommon(date, type);
        }
    },
    watch: {
        dataEventPrizes: {
            handler(newDataEventPrizes) {
                if (newDataEventPrizes.length > 0) {
                    newDataEventPrizes.forEach((item) => {
                        this.arrayObjectPizes.push(initObjectPize(item));
                    });
                } else {
                    this.arrayObjectPizes = [];
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="scss">
.box__preview--common {
    min-width: 170px;
    &--title {
        margin-left: 5px;
        min-width: max-content;
        font-size: 12px;
    }
    &--content {
        margin-left: 10px;
        font-size: 12px;
        margin-bottom: 0;
    }
}
</style>
<style lang="scss" scoped></style>
