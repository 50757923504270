const FORM_CAMPAIGN_DETAIL = {
    name: '',
    kana_name: '',
    email: '',
    phone_number: '',
    type: {
        id: ''
    },
    contact_informations: [],
    note: '',
    address_1: '',
    address_2: '',
    url: '',
    postal_code: '',
    keyword: '',
    give_away_information: {
        inflow_destination: '',
        inflow_rate: '',
        attrition_rate: ''
    },
    number_of_applications: {
        total: '',
        postcard_mail: '',
        web: '',
        palnavi: '',
        sns: '',
        twitter: '',
        instagram: '',
        line: '',
        others: '',
        invalid: ''
    },
    number_of_inquiries: {
        total: '',
        tel: '',
        email: '',
        number_of_cases: '',
        irregular: '',
        irregular_content: ''
    },
    campaign_master_order_scopes: []
};

export { FORM_CAMPAIGN_DETAIL };
