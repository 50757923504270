<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3">営業担当</label>
            <div class="col-sm-9">
                {{ dataForm?.saleStaffObject?.name ?? '' }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3 pr-0">対応ディレクションチーム</label>
            <div class="col-sm-9">
                {{ dataForm?.teamObject?.value ?? '' }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">案件統括</label>
            <div class="col-sm-9">
                {{ dataForm?.form.project_management?.name ?? '' }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">担当</label>
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-sm-9">
                        <div class="d-flex align-items-center">
                            <span style="width: 60px">メイン</span>
                            {{ dataForm?.personInChargeObject?.name ?? '' }}
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
                <div class="row mt-2" v-for="(pTem, k) in dataForm?.listItemPersons" :key="'p' + k">
                    <div class="col-sm-9">
                        <div class="d-flex align-items-center">
                            <span style="width: 60px">サブ{{ k + 1 }}</span>
                            {{ dataForm?.listItemPersons[k].name }}
                        </div>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3 pr-0">エスカレー担当</label>
            <div class="col-sm-9">
                {{ dataForm?.customerTeamPic?.name ?? '' }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">アルバイト</label>
            <div class="col-sm-9">
                {{ handleRenderNameEmployePT(dataForm?.form.part_time_employees) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableSecretariat',
    props: {
        dataForm: Object,
        default: () => {}
    },
    methods: {
        handleRenderNameEmployePT(params) {
            if (params && params.length > 0) {
                return params
                    .map((item) => {
                        return item.name;
                    })
                    .join('、');
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped></style>
