<template>
    <div>
        <div class="table-responsive" style="height: 500px; overflow-y: auto">
            <table class="table mb-0 table-bordered table-box custom__table--sticky">
                <thead>
                    <tr>
                        <th>大項目</th>
                        <th>中項目</th>
                        <th>小項目</th>
                        <th>詳細</th>
                        <th>パートナー</th>
                        <th>ツール</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(itemTscope, kScope) in dataTools" :key="`${kScope}=>kScope3=>kScope2}=>kScope1`">
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.parent?.parent?.parent?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.parent?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.parent?.parent?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.parent?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.key == 'large_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.parent?.parent?.key == 'medium_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.parent?.key == 'medium_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.key == 'medium_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.parent?.key == 'small_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.parent?.value ?? '' }}
                            </div>
                            <div v-if="itemTscope?.master_order_scope?.key == 'small_item'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left">
                            <div v-if="itemTscope?.master_order_scope?.key == 'detail'" style="min-width: 80px">
                                {{ itemTscope?.master_order_scope?.value ?? '' }}
                            </div>
                        </td>
                        <td class="text-left" style="width: 320px">
                            <div style="max-width: 320px">
                                {{ itemTscope.partner?.name ?? '' }}
                            </div>
                        </td>
                        <td class="text-left" style="width: 320px">
                            <div style="max-width: 320px">
                                {{ itemTscope?.tool?.tool ?? '' }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableTools',
    props: {
        dataTools: Array,
        default: () => []
    }
};
</script>

<style lang="scss" scoped></style>
